import { useQuery } from "@tanstack/react-query";
import { axiosCall } from "helpers/axios";
import dayjs from "dayjs";
import { PieChart, Pie, Cell } from "recharts";

interface articulosCreadosDiaProps {
  type: "creators" | "updates";
}

const ArticulosCreadosDia = (props: articulosCreadosDiaProps) => {
  const { type } = props;
  const { isPending, data } = useQuery({
    queryKey: ["articles"],
    queryFn: () => {
      return axiosCall().post("/cms/data", {
        source: "articles",
        params: `filters[$or][0][createdAt][$between]=${dayjs().format(
          "YYYY-MM-DD"
        )}&filters[$or][0][createdAt][$between]=${dayjs(new Date())
          .add(1, "day")
          .format("YYYY-MM-DD")}&populate=deep,5`,
      });
    },
    refetchOnWindowFocus: true,
    select: ({ data }) => {
      const { payload } = data;
      const fullData = payload.map(({ id, attributes }: any) => ({
        id,
        ...attributes,
      }));
      const chartData = fullData.reduce(
        (acc: any, current: any) => {
          return {
            CIA: !current?.createdBy ? acc.CIA + 1 : acc.CIA,
            CAL:
              current?.createdBy?.lastname === "Leguizamon"
                ? acc.CAL + 1
                : acc.CAL,
            CAG:
              current?.createdBy?.lastname === "Garibello"
                ? acc.CAG + 1
                : acc.CAG,
            UAL:
              current?.updatedBy?.lastname === "Leguizamon"
                ? acc.UAL + 1
                : acc.UAL,
            UAG:
              current?.updatedBy?.lastname === "Garibello"
                ? acc.UAG + 1
                : acc.UAG,
          };
        },
        {
          CIA: 0,
          CAL: 0,
          CAG: 0,
          UIA: 0,
          UAL: 0,
          UAG: 0,
        }
      );

      return {
        totals: {
          creators: chartData.CIA + chartData.CAL + chartData.CAG,
          updates: chartData.UAL + chartData.UAG,
        },
        creators: [
          { name: "IA", value: chartData.CIA, color: "#0088FE" },
          { name: "Andres Leguizamon", value: chartData.CAL, color: "#00C49F" },
          { name: "Andres Garibello", value: chartData.CAG, color: "#FFBB28" },
        ],
        updates: [
          { name: "Andres Leguizamon", value: chartData.UAL, color: "#00C49F" },
          { name: "Andres Garibello", value: chartData.UAG, color: "#FFBB28" },
        ],
      };
    },
  });

  const chartData = data ? data[type] : [];
  const Total = data ? data.totals[type] : 0;

  return (
    <div className="card flex flex-col items-center w-[550px] ">
      <span className="text-xl font-bold text-center">
        Articulos {type === "creators" ? "Creados" : "Actualizados"} <br />{" "}
        {dayjs(new Date()).format("YYYY-MM-DD")}
      </span>
      <div className="flex items-center">
        <div className="relative">
          <PieChart width={300} height={250}>
            <Pie
              dataKey="value"
              data={chartData || []}
              innerRadius={60}
              outerRadius={100}
              fill="#8884d8"
              paddingAngle={1}>
              {chartData?.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
          </PieChart>
          <div className="absolute left-0 right-0 top-[94px] flex flex-col items-center">
            <span className="font-bold text-4xl">{Total}</span>
            <span className="font-bold text-sm">Total</span>
          </div>
        </div>
        <div className="">
          {chartData?.map((entry, index) => (
            <div key={`cell-${index}`} className="flex items-center">
              <div
                className="dot mr-2 flex justify-center items-center"
                style={{ background: entry.color }}>
                <span className="text-white text-xs font-bold">
                  {entry.value}
                </span>
              </div>
              <span>{entry.name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ArticulosCreadosDia;
