import {
  ERROR_SAVE_SESION,
  LOADING_SAVE_SESION,
  SAVE_LOGIN_DATA_SESION,
} from "types/sesionTypes";
import { axiosCallLogIn } from "helpers/axios";

export const getLogInData = (params: any) => async (dispatch: any) => {
  dispatch({ type: LOADING_SAVE_SESION });
  try {
    const {
      data: { payload },
    } = await axiosCallLogIn().post("auth/login", params);
    dispatch({
      type: SAVE_LOGIN_DATA_SESION,
      payload: { loginData: payload },
    });
  } catch (error) {
    dispatch({
      type: ERROR_SAVE_SESION,
      payload: error,
    });
  }
};

export const logOut =()=>async (dispatch: any)=>{
  dispatch({
    type: SAVE_LOGIN_DATA_SESION,
    payload: { loginData: {} },
  });
}
