import { useState, useMemo } from "react";
import Layout from "containers/Layout";
import { useQuery, useMutation } from "@tanstack/react-query";
import Avatar from "@mui/material/Avatar";
import { axiosCall } from "helpers/axios";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Table from "components/comon/Table";
import dayjs from "dayjs";
import { getDaterage } from "helpers/dates";
import SocialPublisher from "components/comon/SocialPublisher";
import { getValidImage } from "helpers/general";
import { GridColDef } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import Chip from "@mui/material/Chip";

const top100Films = [
  { label: "Hoy", value: 0 },
  { label: "Hace 7 dias", value: 1 },
  { label: "Este mes", value: 2 },
];

const DEFAULT_DATES = {
  start: dayjs(new Date()).format("YYYY-MM-DD"),
  end: dayjs(new Date()).add(1, "day").format("YYYY-MM-DD"),
};

const Articulos = () => {
  const [dates, setDate] = useState(DEFAULT_DATES);

  const filters = useMemo(
    () => ({
      start: dates.start,
      end: dates.end,
    }),
    [dates]
  );

  const { isPending, data } = useQuery({
    queryKey: ["articles", filters],
    queryFn: () => {
      return axiosCall().post("/cms/data", {
        source: "articles",
        params: `filters[$or][0][createdAt][$between]=${filters.start}&filters[$or][0][createdAt][$between]=${filters.end}&populate=deep,5`,
      });
    },
    select: ({ data }) => {
      const {
        meta: { pagination },
        payload,
      } = data;
      const fullData = payload.map(({ id, attributes }: any) => ({
        id,
        ...attributes,
      }));
      return {
        pagination,
        payload: fullData,
      };
    },
  });

  const handleDateChange = (event: any, newValue: any) => {
    if (newValue) {
      const { start, end } = getDaterage(newValue.value);
      setDate({ start, end });
    } else {
      setDate(DEFAULT_DATES);
    }
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "Id", width: 70 },
    {
      field: "title",
      headerName: "Nombre",
      flex: 100,
      renderCell: ({ row }) => {
        return (
          <Link to={`/redactor/${row.id}`} className="flex items-center gap-2">
            <Avatar
              alt="Remy Sharp"
              src={`${process.env.REACT_APP_API_STRAPI_BASE_URL}${row?.image?.data?.attributes?.formats?.thumbnail?.url}`}
            />
            <span>{row?.title}</span>
          </Link>
        );
      },
    },
    {
      field: "publishedAt",
      headerName: "Estado",
      width: 100,
      renderCell: ({ value }) =>
        value ? <Chip label="Publicado" color="success" size="small" /> :  <Chip label="Borrador" color="info" size="small" />,
    },
    {
      field: "createdBy",
      headerName: "Autor",
      width: 150,
      renderCell: ({ value }) =>
        value ? `${value.firstname} ${value.lastname}` : "IA",
    },
    {
      field: "createdAt",
      headerName: "Fecha",
      width: 170,
      renderCell: ({ value }) => dayjs(value).format("YYYY-MM-DD HH:mm"),
    },
    {
      field: "social",
      headerName: "Compartir",
      width: 170,
      renderCell: ({ row }) => (
        <SocialPublisher
          id={row.id}
          img={getValidImage(row?.image?.data?.attributes?.formats)}
          caption={row.description}
          title={row?.title}
          section={row?.subsection?.data?.attributes?.name}
        />
      ),
    },
  ];

  return (
    <Layout>
      <h1 className="text-2xl font-bold mb-6">Articulos</h1>
      <div className="flex items-center justify-between mb-8">
        <Autocomplete
          defaultValue={{ label: "Hoy", value: 0 }}
          disablePortal
          id="combo-box-demo"
          options={top100Films}
          sx={{ width: 200 }}
          size="small"
          onChange={handleDateChange}
          renderInput={(params) => <TextField {...params} label="Periodo" />}
        />
        <div>
          <span className="text-2xl">{`Total: ${
            data?.pagination?.total || 0
          }`}</span>
        </div>
      </div>
      <Table rows={data?.payload} columns={columns} loading={isPending} />
    </Layout>
  );
};

export default Articulos;
