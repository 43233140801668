import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

interface textEditorProps {
  body: string;
}

const TextEditor = (props: textEditorProps) => {
  const { body = "" } = props;
  return (
    <CKEditor
      editor={ClassicEditor}
      data={body}
      onChange={(event, editor) => {
        const data = editor.getData();
        console.log({ event, editor, data });
      }}
      config={{
        removePlugins: [
          "EasyImage",
          "ImageUpload",
          "MediaEmbed",
          "Table",
          "Indent",
        ],
      }}
    />
  );
};

export default TextEditor;
