import { axiosCall } from "helpers/axios";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  ReferenceLine,
  Tooltip,
} from "recharts";

const ID_GARIBELO = 3;
const ID_LEGUIZAMON = 2;

interface articulosCreadosSemanProps {
  type: "createdAt" | "updatedAt";
}

const ArticulosCreadosSeman = (props: articulosCreadosSemanProps) => {
  const { type } = props;

  const author = type == "createdAt" ? "createdBy" : "updatedBy";

  const { isPending, data } = useQuery({
    queryKey: ["articles_week"],
    queryFn: () => {
      return axiosCall().post("/cms/data", {
        source: "articles",
        params: `filters[$or][0][createdAt][$between]=${dayjs()
          .add(-6, "day")
          .format("YYYY-MM-DD")}&filters[$or][0][createdAt][$between]=${dayjs(
          new Date()
        )
          .add(1, "day")
          .format("YYYY-MM-DD")}&populate=deep,5`,
      });
    },
    refetchOnWindowFocus: true,
    select: ({ data }) => {
      const { payload } = data;
      const fullData = payload.map(({ id, attributes }: any) => ({
        id,
        ...attributes,
        [type]: dayjs(attributes[type]).format("DD"),
      }));

      return fullData.reduce((acc: any, item: any) => {
        const itemCurrent = acc.findIndex(
          (accItem: any) => accItem.date == dayjs(item.publishedAt).format("DD")
        );
        if (itemCurrent > -1) {
          if (item[author]?.id == ID_LEGUIZAMON) {
            acc[itemCurrent].AL = acc[itemCurrent].AL + 1;
          }
          if (item[author]?.id == ID_GARIBELO) {
            acc[itemCurrent].AG = acc[itemCurrent].AG + 1;
          }
          return acc;
        } else {
          return [
            ...acc,
            {
              date: dayjs(item.publishedAt).format("DD"),
              AL: item[author]?.id == ID_LEGUIZAMON ? 1 : 0,
              AG: item[author]?.id == ID_GARIBELO ? 1 : 0,
            },
          ];
        }
      }, []);
    },
  });
  return (
    <div className="card flex flex-col items-center">
      <span className="text-xl font-bold text-center">
        Articulos {type === "createdAt" ? "Creados" : "Actualizados"} <br /> del{" "}
        {dayjs().add(-6, "day").format("DD")} al {dayjs().format("DD")}
      </span>
      <LineChart
        width={600}
        height={250}
        data={data}
        margin={{
          top: 5,
          right: 50,
          left: 0,
          bottom: 5,
        }}>
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <ReferenceLine y={10} label="Min" stroke="red" />
        <Line dataKey="AL" stroke="#00C49F" />
        <Line dataKey="AG" stroke="#FFBB28" />
      </LineChart>
    </div>
  );
};

export default ArticulosCreadosSeman;
