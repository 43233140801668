import axios from "axios";
import { store } from "store";
import {logOut} from "actions/sesionActions"

export const axiosCallLogIn = () => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
      Accept: "application/json",
      "x-rapidapi-host": "famous-quotes4.p.rapidapi.com",
      "x-rapidapi-key": "<your-key-here>",
    },
  });
  return instance;
};
export const axiosCall = () => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
      Authorization: `Bearer ${store.getState().sesionReducer.loginData.jwt}`,
      Accept: "application/json",
      "x-rapidapi-host": "famous-quotes4.p.rapidapi.com",
      "x-rapidapi-key": "<your-key-here>",
    },
  });
  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status ===401) {
        store.dispatch(logOut())
        return Promise.reject(error.response.data);
      }
      return Promise.reject(error.message);
    }
  );
  return instance;
};
