import {
  ERROR_SAVE_SESION,
  LOADING_SAVE_SESION,
  SAVE_LOGIN_DATA_SESION,
} from "types/sesionTypes";

const INITIAL_STATE = {
  error: false,
  loading: false,
  loginData: {},
};

function sesionReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case LOADING_SAVE_SESION:
      return { ...state, error: false, loading: true };
    case ERROR_SAVE_SESION:
      return { ...state, error: true, loading: false };
    case SAVE_LOGIN_DATA_SESION:
      return {
        ...state,
        loginData: action.payload.loginData,
        error: false,
        loading: false,
      };
    default:
      return state;
  }
}

export default sesionReducer;
