import { axiosCall } from "helpers/axios";
import { GridColDef } from "@mui/x-data-grid";
import Table from "components/comon/Table";
import { useQuery } from "@tanstack/react-query";
import TendenciasActins from "components/inputs/TendenciasActins";
import dayjs from "dayjs";

const KeyWordsTable = () => {
  const { isPending, error, data, refetch } = useQuery({
    queryKey: ["tendencias_all"],
    queryFn: () =>
      axiosCall().post("/data", {
        query: "SELECT * FROM `KEYWORDS_LIST` WHERE `status`=1;",
      }),
    select: ({ data }) => {
      return data?.payload;
    },
  });

  const columns: GridColDef[] = [
    { field: "country", headerName: "Pais", width: 70 },
    { field: "label", headerName: "Nombre", flex: 100 },
    {
      field: "updated_at",
      headerName: "Actualizado",
      width: 170,
      renderCell: ({ value }) => dayjs(value).format("YYYY-MM-DD HH:mm"),
    },
    {
      field: "id",
      headerName: "Action",
      renderCell: ({ row }) => {
        return <TendenciasActins item={row} reload={refetch} edit />;
      },
    },
  ];

  return (
    <div>
      <TendenciasActins add reload={refetch} />
      <div className="h-4" />
      <Table rows={data} columns={columns} loading={isPending} />
    </div>
  );
};

export default KeyWordsTable;
