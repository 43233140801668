import { useState, forwardRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

interface linksModalProps {
  links: string;
  keyWords: string;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LinksModal = (props: linksModalProps) => {
  const { links = "", keyWords } = props;
  const [open, setOpen] = useState(false);

  return (
    <div>
      <button onClick={() => setOpen(true)}>
        <span>{keyWords}</span>
      </button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
            sx: {
              width: "100%",
              maxWidth: "400px!important",
            },
          }}
        >
        <DialogTitle>{"Links"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {JSON.parse(links).map((item: any) => (
              <div className="flex justify-between items-center mb-2">
                <div className="flex flex-col justify-center">
                  <span className="font-semibold">{`Fuente: ${item.source}`}</span>
                  <span>{item.time}</span>
                </div>
                <Button variant="contained" href={item.url} target="_blanck" color="info">
                  LINK
                </Button>
              </div>
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LinksModal;
