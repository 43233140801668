interface getValidImageProps {
  large: { url: string };
  medium: { url: string };
  small: { url: string };
}

export const getValidImage = (images: getValidImageProps): string => {
  return `${process.env.REACT_APP_API_STRAPI_BASE_URL}${
    images?.large?.url || images?.medium?.url || images?.small?.url
  }`;
};
