import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

interface datePickerProps {
  containerClass?: string;
}

const DatePicker = (props: datePickerProps) => {
  const { containerClass = "" } = props;

  return (
    <div className={containerClass}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DateTimePicker"]}>
          <DateTimePicker
            label="Fecha de publicacion"
          />
        </DemoContainer>
      </LocalizationProvider>
    </div>
  );
};

export default DatePicker;
