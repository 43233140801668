import {
  Fragment,
  useState,
  forwardRef,
  useEffect,
  useRef,
  useCallback,
} from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import SocialSelect from "components/inputs/SocialSelect";
import { toPng } from "html-to-image";
import TextField from "@mui/material/TextField";
import { TransitionProps } from "@mui/material/transitions";
import { htmlToText } from "helpers/text";

interface socialPublisherProps {
  id: number;
  img: string;
  caption: string;
  title: string;
  section?: string;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DEFAULT_DATA = {
  description: "",
};

const SocialPublisher = (props: socialPublisherProps) => {
  const { id, img, caption = "", title = "", section = "" } = props;

  const imgRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(DEFAULT_DATA);
  const [imgTitle, setImgTitle] = useState("");

  useEffect(() => {
    setData((prevstate) =>
      prevstate
        ? { ...prevstate, description: htmlToText(caption) }
        : { description: htmlToText(caption) }
    );
  }, [caption]);

  useEffect(() => {
    setImgTitle(htmlToText(title));
  }, [title]);

  const handlePublish = useCallback(() => {
    if (imgRef.current === null) {
      return;
    }
    toPng(imgRef.current, { cacheBust: true })
      .then((dataUrl) => {
        console.log(dataUrl);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [imgRef]);

  const { description } = data;

  return (
    <Fragment>
      <div onClick={() => setOpen(true)} className="cursor-pointer">
        <FacebookIcon />
        <InstagramIcon />
        <TwitterIcon />
      </div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "750px!important",
          },
        }}
      >
        <DialogTitle>Compartir</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="flex">
              <div className="w-[400px] h-[400px]">
                <div className="transforScale">
                  <div className="w-[1200px] h-[1200px] relative" ref={imgRef}>
                    <img
                      src={img}
                      className="w-[1200px] h-[1200px] absolute object-cover"
                    />
                    <img
                      className="w-[600px] absolute top-10 left-10"
                      src={require("assets/logo-mobile.png")}
                      alt="Logo"
                    />
                    <div className="absolute bottom-10 w-full">
                      <div className=" bg-primary px-8 py-6 mb-6 clip-path w-6/12">
                        <span className="text-white portal-font font-bold text-7xl">
                          {section}
                        </span>
                      </div>
                      <div className=" bg-primary pl-8 pr-10 py-6 w-10/12 clip-path">
                        <span className="text-white portal-font text-6xl">
                          {imgTitle}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ml-4 pt-2 w-full">
                <TextField
                  id="outlined-multiline-flexible"
                  label="Titulo"
                  value={imgTitle}
                  onChange={(event) => setImgTitle(event.target.value)}
                  multiline
                  maxRows={3}
                  fullWidth
                />
                <div className="h-4" />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Descripcion"
                  value={description}
                  multiline
                  maxRows={8}
                  fullWidth
                />
                <div className="h-4" />
                <SocialSelect />
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePublish} variant="contained" color="success">
            Compartir
          </Button>
          <Button
            onClick={() => setOpen(false)}
            variant="outlined"
            color="warning"
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default SocialPublisher;
