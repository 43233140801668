import { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const SocialSelect = () => {
  const [faceBook, setFacebook] = useState(false);
  const [instagram, setInstagram] = useState(false);
  const [twitter, setTwitter] = useState(false);

  return (
    <div>
      <span>Seleccione red social:</span>
      <div className="flex">
        <Checkbox
          {...label}
          icon={<FacebookIcon />}
          checkedIcon={<FacebookIcon />}
          checked={faceBook}
          onChange={() => setFacebook(!faceBook)}
          color="info"
        />
        <Checkbox
          {...label}
          icon={<InstagramIcon />}
          checkedIcon={<InstagramIcon />}
          checked={instagram}
          onChange={() => setInstagram(!instagram)}
          color="error"
        />
        <Checkbox
          {...label}
          icon={<TwitterIcon />}
          checkedIcon={<TwitterIcon />}
          checked={twitter}
          onChange={() => setTwitter(!twitter)}
          color="info"
        />
        <div>
          <Checkbox {...label} color="info" />
          <span>Todas</span>
        </div>
      </div>
    </div>
  );
};

export default SocialSelect;
