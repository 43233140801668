import { useState, forwardRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import EditIcon from "@mui/icons-material/Edit";

import TapContainer from "containers/TapContainer";

interface imageSelector {
  src: string;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ImageSelector = (props: imageSelector) => {
  const { src } = props;
  const [open, setOpen] = useState(false);

  return (
    <div className="p-4 border border-solid border-[#CCCED0]">
      <div className="aspect-[16/9] relative overflow-hidden">
        <img
          src={src}
          alt="image selector"
          className="object-cover absolut w-full h-full"
        />
        <div className="glass w-[40px] h-[40px]" onClick={() => setOpen(true)}>
          <EditIcon />
        </div>
      </div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "400px!important",
          },
        }}>
        <DialogTitle>{"Imagenes"}</DialogTitle>
        <DialogContent>
          <TapContainer />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ImageSelector;
