import { useState, Fragment, useEffect } from "react";
import Modal from "containers/Modal";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import dayjs from "dayjs";

interface historyChart {
  open: boolean;
  setOpen: Function;
  info: string;
}

const HistoryChart = (props: historyChart) => {
  const { open, setOpen, info } = props;
  const [data, setData] = useState<any>(undefined);

  useEffect(() => {
    if (open && info) {
      const array = JSON.parse(info);
      array.sort((a: any, b: any) =>
        dayjs(a.created_at).diff(dayjs(b.created_at))
      );
      const chartData = array.map((item: any) => ({
        date: dayjs(item.created_at).format("HH:mm"),
        value: item.value,
      }));
      setData(chartData);
    }
  }, [info, open]);

  return (
    <Fragment>
      <Modal open={open} setOpen={setOpen} title="Historial" xl>
        <div className="mr-6">
          <LineChart
            width={750}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />

            <Line type="monotone" dataKey="value" stroke="#82ca9d" />
          </LineChart>
        </div>
      </Modal>
    </Fragment>
  );
};

export default HistoryChart;
