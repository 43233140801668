import { createStore, applyMiddleware } from 'redux';
import reducer from 'reducers/index';
import thunkMiddleware from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';


const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['sesionReducer'],
};

const persistedReducer = persistReducer(persistConfig, reducer)

const store = createStore(
    persistedReducer, 
    applyMiddleware(thunkMiddleware)
);

const persistor = persistStore(store);

export { store, persistor };