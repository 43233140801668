import dayjs from "dayjs";

interface getDaterageResponse {
  start: string;
  end: string;
}

export const getDaterage = (type: number): getDaterageResponse => {
  switch (type) {
    case 0:
      return {
        end: dayjs(new Date()).add(1, "day").format("YYYY-MM-DD"),
        start: dayjs(new Date()).format("YYYY-MM-DD"),
      };
    case 1:
      return {
        end: dayjs(new Date()).format("YYYY-MM-DD"),
        start: dayjs(new Date()).add(-7, "day").format("YYYY-MM-DD"),
      };
    case 2:
      return {
        end: dayjs(new Date()).format("YYYY-MM-DD"),
        start: dayjs(new Date()).date(1).format("YYYY-MM-DD"),
      };
    default:
      return {
        start: "0",
        end: "0",
      };
  }
};
