import Layout from "containers/Layout";
import ArticulosCreadosDia from "components/charts/ArticulosCreadosDia";
import ArticulosCreadosSeman from "components/charts/ArticulosCreadosSeman";

const Metrics = () => {
  return (
    <Layout>
      <h1 className="text-2xl font-bold mb-6">Metricas:</h1>
      <div className="flex gap-4 mb-4">
        <ArticulosCreadosDia type="creators" />
        <ArticulosCreadosDia type="updates" />
      </div>
      <div className="flex gap-4 mb-4">
        <ArticulosCreadosSeman type="createdAt"/>
        <ArticulosCreadosSeman type="updatedAt"/>
      </div>
    </Layout>
  );
};

export default Metrics;
