import { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { connect } from "react-redux";
import { sha512 } from "js-sha512";
import { Navigate } from "react-router-dom";
import Alert from '@mui/material/Alert';

import * as sesionActions from "actions/sesionActions";
const { getLogInData } = sesionActions;

interface LogIn {
  sesionReducer: {
    error: boolean;
    loading: boolean;
    loginData: any;
  };
  getLogInData: Function;
}

const LogIn = (props: LogIn) => {
  const {
    sesionReducer: {
      loginData: { jwt },
      loading,
      error,
    },
    getLogInData,
  } = props;

  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");

  const handleLogIn = () => {
    const params = {
      usuario: user,
      password: sha512(password),
    };
    getLogInData(params);
  };

  if (jwt) {
    return <Navigate to="/metricas" replace={true} />;
  }

  const inValidData = !user || !password;

  return (
    <div className="gradientBackground w-full h-screen flex justify-center items-center">
      <Paper elevation={3}>
        <div className="flex flex-col px-8 py-14 w-[400px]">
          <img
            className="mb-10 w-5/6 mx-auto"
            src={require("assets/logo.png")}
            alt="Logo"
          />
          <span className="text-center mb-8 text-sm">
            Por favor ingrese sus credenciales
          </span>
          <TextField
            size="small"
            id="user"
            label="Usuario"
            variant="outlined"
            value={user}
            onChange={(event) => {
              setUser(event.target.value);
            }}
            disabled={loading}
          />
          <div className="h-4" />
          <TextField
            size="small"
            id="password"
            type="password"
            label="Contraseña"
            variant="outlined"
            value={password}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            disabled={loading}
          />
          <div className="h-2" />
          {error&&<Alert severity="error">Valide sus credenciales por favor</Alert>}
          <div className="h-2" />
          <Button
            variant="contained"
            color="primary"
            onClick={handleLogIn}
            disabled={loading || inValidData}>
            Iniciar Sesion
          </Button>
        </div>
      </Paper>
    </div>
  );
};

const mapStateToProps = ({ sesionReducer }: any) => {
  return { sesionReducer };
};

const mapDispatchToProps = { getLogInData };

export default connect(mapStateToProps, mapDispatchToProps)(LogIn);
