import MenuHeader from "components/body/MenuHeader";

const Layout = (props: any) => {
  const { children } = props;
  return (
    <div>
      <MenuHeader />
      <div className="px-12 pt-8 pb-10 bg-bgMain main">{children}</div>
    </div>
  );
};

export default Layout;
