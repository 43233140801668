import Layout from "containers/Layout";
import { useParams } from "react-router-dom";
import TextEditor from "components/inputs/TextEditor";
import { useQuery } from "@tanstack/react-query";
import { axiosCall } from "helpers/axios";
import Back from "components/comon/Back";
import TextField from "@mui/material/TextField";
import { getValidImage } from "helpers/general";
import ImageSelector from "components/inputs/ImageSelector";
import Button from "@mui/material/Button";
import DatePicker from "components/inputs/DatePicker";

const Redactor = () => {
  const { id } = useParams();
  const { isPending, data } = useQuery({
    queryKey: ["articles", id],
    queryFn: () => {
      return axiosCall().post("/cms/data", {
        source: "articles",
        params: `filters[$or][0][id]=${id}&populate=deep,5`,
      });
    },
    select: ({ data: { payload: [article] = {} } = {} }): any => ({
      id,
      ...article?.attributes,
    }),
  });

  const {
    title = "",
    body = "",
    description = "",
    image: { data: { attributes: { formats = {} } = {} } = {} } = {},
  } = data || {};

  return (
    <Layout>
      <Back />
      <div className="flex justify-between items-center">
        <h1 className="text-3xl mb-6 mt-2 font-bold">Redactor:</h1>
        <div className="flex gap-4">
          <Button color="success" variant="contained">Guardar</Button>
          <Button color="info" variant="contained">Publicar</Button>
        </div>
      </div>
      <div className="card">
        <h2 className="font-bold text-xl mb-3">{title}</h2>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col pt-4">
            <DatePicker containerClass="mb-6"/>
            <TextField
              label="Titulo"
              id="filled-size-small"
              value={title}
              size="small"
            />
            <span className="font-bold text-slate-600 mb-1 mt-4">
              Descripcion:
            </span>
            <TextEditor body={description} />
            <span className="font-bold text-slate-600 mb-1 mt-4">Imagen:</span>
            <ImageSelector src={getValidImage(formats)} />
          </div>
          <div>
            <span className="font-bold text-slate-600 mb-1">Cuerpo:</span>
            <TextEditor body={body} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Redactor;
