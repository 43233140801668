import { ArrowDropUp } from "@mui/icons-material";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import { GridColDef } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import HistoryChart from "components/charts/HistoryChart";
import Table from "components/comon/Table";
import dayjs from "dayjs";
import { axiosCall } from "helpers/axios";
import { useState } from "react";

const LastSEOThemes = () => {
  const [info, setInfo] = useState("");
  const [open, setOpen] = useState(false);

  const { isPending, error, data, refetch } = useQuery({
    queryKey: ["tendencias_all"],
    queryFn: () =>
      axiosCall().post("/data", {
        query:
          "SELECT KEYWORDS_TOPICS_TRENDS.id AS id, KEYWORDS_TOPICS_TRENDS.label AS label, KEYWORDS_LIST.label AS keyword, KEYWORDS_LIST.country AS country, KEYWORDS_TOPICS_TRENDS.keyword_id, JSON_ARRAYAGG(JSON_OBJECT('created_at', created_at,'value', value)) AS historial_values FROM `KEYWORDS_TOPICS_TRENDS`, `KEYWORDS_LIST` WHERE KEYWORDS_LIST.id = KEYWORDS_TOPICS_TRENDS.keyword_id AND KEYWORDS_TOPICS_TRENDS.created_at >= NOW() - INTERVAL 3 HOUR GROUP BY KEYWORDS_TOPICS_TRENDS.label ORDER BY KEYWORDS_TOPICS_TRENDS.value DESC;",
      }),
    select: ({ data }) => {
      return data?.payload;
    },
  });

  const handleOpen = (value: string) => {
    setInfo(value);
    setOpen(true);
  };

  const getTrend = (item: string) => {
    if (item) {
      const array = JSON.parse(item);

      array.sort((a: any, b: any) =>
        dayjs(b.created_at).diff(dayjs(a.created_at))
      );
      const avg =
        array.reduce((total: any, valor: any) => total + valor.value, 0) /
        array.length;
      const last = array[0];
      return (
        <div className="flex items-center justify-between">
          {last.value > avg ? (
            <ArrowDropUp style={{ color: "green", height: 45, width: 45 }} />
          ) : last.value === avg ? (
            <span className="w-10" />
          ) : (
            <ArrowDropDown style={{ color: "red", height: 45, width: 45 }} />
          )}
          <span>{last.value.toLocaleString()}</span>
        </div>
      );
    }
    return "--";
  };

  const columns: GridColDef[] = [
    { field: "country", headerName: "Country", flex: 10 },
    { field: "keyword", headerName: "Keyword", flex: 20 },
    { field: "label", headerName: "Topic", flex: 50 },
    {
      field: "historial_values",
      headerName: "Value",
      width: 150,
      renderCell: ({ value }) => {
        return (
          <div
            onClick={() => handleOpen(value)}
            className="cursor-pointer w-full"
          >
            {getTrend(value)}
          </div>
        );
      },
    },
  ];
  return (
    <div>
      <Table rows={data} columns={columns} loading={isPending} />
      <HistoryChart open={open} setOpen={setOpen} info={info} />
    </div>
  );
};

export default LastSEOThemes;
