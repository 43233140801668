import { connect } from "react-redux";
import { Navigate, Link, useLocation } from "react-router-dom";
import classNames from "classnames";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";

import * as sesionActions from "actions/sesionActions";
const { logOut } = sesionActions;

interface menuHeaderProps {
  sesionReducer: {
    error?: boolean;
    loading?: boolean;
    loginData?: any;
  };
  logOut: Function;
}

const MenuHeader = (props: menuHeaderProps) => {
  const {
    logOut,
    sesionReducer: {
      loginData: { jwt = "", usuario: { nombre = "" } = {} },
    },
  } = props;

  const location = useLocation();

  if (!jwt) {
    return <Navigate to="/log-in" replace={true} />;
  }

  return (
    <div className="h-[75px] bg-dark flex justify-between items-center px-12">
      <div className="flex">
        <img
          className="w-[180px] my-auto mr-4"
          src={require("assets/logo-mobile.png")}
          alt="Logo"
        />
        <Link to="/metricas">
          <h2
            className={classNames(
              "text-white font-semibold mx-4 ",
              location.pathname === "/metricas" && "border-b-2 border-b-white"
            )}>
            Metricas
          </h2>
        </Link>
        <Link to="/seo-Tool">
          <h2
            className={classNames(
              "text-white font-semibold mx-4 ",
              location.pathname === "/seo-Tool" && "border-b-2 border-b-white"
            )}>
            SEO Tool
          </h2>
        </Link>
        <Link to="/publisher">
          <h2
            className={classNames(
              "text-white font-semibold mx-4 ",
              location.pathname === "/publisher" && "border-b-2 border-b-white"
            )}>
            Publisher
          </h2>
        </Link>
        {/*
        <Link to="/articulos">
          <h2
            className={classNames(
              "text-white font-semibold mx-4 ",
              location.pathname === "/articulos" && "border-b-2 border-b-white"
            )}>
            Articulos
          </h2>
        </Link>
         */}
      </div>
      <div>
        <AccountCircleIcon style={{ color: "#FFF" }} fontSize="large" />
        <span className="text-white mx-4">{nombre}</span>
        <button onClick={() => logOut()}>
          <LogoutIcon style={{ color: "#FFF" }} fontSize="large" />
        </button>
      </div>
    </div>
  );
};
const mapStateToProps = ({ sesionReducer }: any) => {
  return { sesionReducer };
};

const mapDispatchToProps = { logOut };

export default connect(mapStateToProps, mapDispatchToProps)(MenuHeader);
