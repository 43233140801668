import { useState, forwardRef, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { useMutation } from "@tanstack/react-query";
import { axiosCall } from "helpers/axios";
import { CONTRIES } from "helpers/const";

interface tendenciasActinsProps {
  item?: any;
  add?: boolean;
  edit?: boolean;
  reload: Function;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DEFAULT_DATA = {
  label: "",
  country: CONTRIES[0],
};

const TendenciasActins = (props: tendenciasActinsProps) => {
  const { item, add = false, reload, edit = false } = props;
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [data, setData] = useState(DEFAULT_DATA);

  const change = useMutation({
    mutationFn: (query: string) => {
      return axiosCall().post("/data", {
        query,
      });
    },
    onSuccess: () => {
      reload();
      setConfirmOpen(false);
      setOpen(false);
    },
  });

  const handleDelete = () => {
    const { id } = item;
    const query = `UPDATE KEYWORDS_LIST SET status = 0 WHERE id = ${id}`;
    change.mutate(query);
  };

  const handleSave = () => {
    const { country, label} = data;
    const query = edit
      ? `UPDATE KEYWORDS_LIST SET country = '${country.value}', label = '${label}' WHERE id = ${item?.id}`
      : `INSERT INTO KEYWORDS_LIST (country, label, status) VALUES ('${country.value}', '${label}', 1)`;
    change.mutate(query);
  };

  useEffect(() => {
    if(open||confirmOpen){
      if (item) {
        const country = CONTRIES.find(
          (element) => element.value === item.country
        );
        setData((prevState: any) => ({
          ...prevState,
          country: country,
          label: item.label,
        }));
      } else {
        setData(DEFAULT_DATA);
      }
    }
  }, [open, confirmOpen]);

  const { label, country } = data;

  const isInValidData = !label || !country;

  return (
    <div>
      <div>
        {add ? (
          <Button
            variant="contained"
            color="success"
            onClick={() => setOpen(true)}>
            Agregar KeyWord
          </Button>
        ) : (
          <div className="cursor-pointer">
            <EditIcon onClick={() => setOpen(true)} />
            <DeleteIcon onClick={() => setConfirmOpen(true)} />
          </div>
        )}
      </div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>{"Use Google's location service?"}</DialogTitle>
        <DialogContent>
          <div className="py-4 flex flex-col gap-6">
            <TextField
              size="small"
              id="Nombre"
              label="Nombre"
              variant="outlined"
              value={label}
              onChange={(event) => {
                setData((prevState) => ({
                  ...prevState,
                  label: event.target.value,
                }));
              }}
              disabled={false}
            />
            <Autocomplete
              disablePortal={false}
              id="combo-box-demo"
              value={country}
              options={CONTRIES}
              size="small"
              onChange={(event, newValue) =>
                setData((prevState: any) => ({
                  ...prevState,
                  country: newValue,
                }))
              }
              renderInput={(params) => <TextField {...params} label="Pais" />}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="success"
            onClick={handleSave}
            disabled={isInValidData}>
            Guardar
          </Button>
          <Button
            variant="contained"
            color="info"
            onClick={() => setOpen(false)}>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={confirmOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setConfirmOpen(false)}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>{"Eliminar"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            ¿Seguro quiere eliminar el item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleDelete}>
            Continuar
          </Button>
          <Button
            variant="contained"
            color="info"
            onClick={() => setConfirmOpen(false)}>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TendenciasActins;
