import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Navigate } from 'react-router-dom';

import Publisher from "pages/Dashboard/Publisher/Publisher";
import Articulos from "pages/Dashboard/Articulos/Articulos";
import Redactor from "pages/Dashboard/Redactor/Redactor";
import SEOTool from "pages/Dashboard/SEOTool/SEOTool";
import Metrics from "pages/Dashboard/Metrics/Metrics";
import LogIn from "pages/LogIn/LogIn";

const Home = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/metricas" replace={true} />} />
        <Route path="publisher" element={<Publisher />}/>
        <Route path="articulos" element={<Articulos />}/>
        <Route path="seo-Tool" element={<SEOTool />}/>
        <Route path="metricas" element={<Metrics />}/>
        <Route path="redactor/:id" element={<Redactor />}/>
        <Route path="log-in" element={<LogIn />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Home;
