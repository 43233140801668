import { forwardRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { TransitionProps } from "@mui/material/transitions";

import Slide from "@mui/material/Slide";

interface modalProps {
  open: boolean;
  setOpen: Function;
  title?: string;
  children: string | JSX.Element | JSX.Element[];
  xl?: boolean;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Modal = (props: modalProps) => {
  const { open, setOpen, children, title = "Modal", xl = false } = props;
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setOpen(false)}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: xl ? "800px!important" : "500px!important",
        },
      }}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="info" onClick={() => setOpen(false)}>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
