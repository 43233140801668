import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Back =()=>{
    const navigate = useNavigate();
    return (
        <button className="flex items-center text-blue-700" onClick={() => navigate(-1)}>
            <ArrowBackIcon/>
            <span className="ml-1 font-bold text-xl">Back </span>
        </button>
    )
}

export default Back;