import Layout from "containers/Layout";
import { GridColDef } from "@mui/x-data-grid";
import Table from "components/comon/Table";
import { useQuery, useMutation } from "@tanstack/react-query";
import Chip from "@mui/material/Chip";
import { axiosCall } from "helpers/axios";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import LinksModal from "components/comon/LinksModal";

const Publisher = () => {
  const { isPending, error, data, refetch } = useQuery({
    queryKey: ["tendencias"],
    queryFn: () => axiosCall().get("/tendencias"),
    select: ({ data }) => {
      return data?.payload;
    },
  });

  const createarticle = useMutation({
    mutationFn: (id: number) => {
      return axiosCall().put("/crear-articulo", { id });
    },
    onSuccess: () => refetch(),
  });

  const createDraft = useMutation({
    mutationFn: (id: number) => {
      return axiosCall().put("/crear-borrador", { id });
    },
    onSuccess: () => refetch(),
  });

  const cancelPublish = useMutation({
    mutationFn: (id: number) => {
      return axiosCall().put("/cancelar-articulo", { id });
    },
    onSuccess: () => refetch(),
  });

  const handleRefresh = () => {
    refetch();
  };

  const handlePublish = (type: string, id: number) => {
    switch (type) {
      case "full":
        createarticle.mutate(id);
        break;
      case "draft":
        createDraft.mutate(id);
        break;
      case "cancel":
        cancelPublish.mutate(id);
        break;
    }
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "keywords",
      headerName: "Palabras clave",
      flex: 100,
      renderCell: ({ row }) => {
        if (!row) {
          return "";
        }
        return <LinksModal links={row.data} keyWords={row.keywords} />;
      },
    },
    { field: "country", headerName: "Pais", width: 70 },
    {
      field: "section",
      headerName: "Tendencias",
      width: 130,
      renderCell: ({ value }) => {
        switch (value) {
          case "b":
            return "Negocios";
          case "t":
            return "Tecnología";
          case "e":
            return "Entretenimiento";
          case "m":
            return "Salud";
          case "h":
            return "Tendencias";
          case "s":
            return "Deportes";
        }
      },
    },
    { field: "times", headerName: "Tendencia", width: 90 },
    {
      field: "data",
      headerName: "Numero de Referencias",
      width: 170,
      renderCell: ({ value }) => {
        if (!value) {
          return "";
        }
        return <span>{JSON.parse(value).length}</span>;
      },
    },
    {
      field: "status",
      headerName: "Estado",
      width: 150,
      renderCell: ({ value }) => {
        switch (value) {
          case 0:
            return "";
          case 1:
            return (
              <Chip
                label="Publicacion en cola"
                color="secondary"
                size="small"
              />
            );
          case 2:
            return <Chip label="Publicado" color="success" size="small" />;
          case 3:
            return (
              <Chip label="Borrador en cola" color="secondary" size="small" />
            );
          case 4:
            return (
              <Chip label="Borrador Generado" color="success" size="small" />
            );
          case 7:
            return <Chip label="En progreso" color="warning" size="small" />;
          case 8:
            return <Chip label="Error" color="error" size="small" />;
          default:
            return " ";
        }
      },
    },
    {
      field: "updated_at",
      headerName: "Fecha",
      width: 170,
      renderCell: ({ value }) => dayjs(value).format("YYYY-MM-DD HH:mm"),
    },
    {
      field: "action",
      headerName: "Acciones",
      width: 210,
      renderCell: ({ row }) => {
        const { status, id } = row;
        if (status == 2 || status == 4 || status == 7) {
          return "";
        }
        if (status == 3 || status == 1) {
          return (
            <Button
              size="small"
              variant="outlined"
              color="info"
              onClick={() => handlePublish("cancel", id)}>
              Cancelar
            </Button>
          );
        }
        return (
          <div className="flex gap-2">
            <Button
              size="small"
              variant="outlined"
              color="warning"
              onClick={() => handlePublish("full", id)}>
              Publicar
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="warning"
              onClick={() => handlePublish("draft", id)}>
              Borrador
            </Button>
          </div>
        );
      },
    },
  ];

  const isLoading =
    isPending || createarticle.isPending || createDraft.isPending;

  return (
    <Layout>
      <h1 className="text-2xl font-bold mb-6">Publicador</h1>
      <Button
        variant="contained"
        color="primary"
        onClick={handleRefresh}
        disabled={isLoading}>
        Actualizar
      </Button>
      <div className="h-6" />
      <Table rows={data} columns={columns} loading={isLoading} />
    </Layout>
  );
};

export default Publisher;
