import { useState } from "react";
import Layout from "containers/Layout";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import KeyWordsTable from "containers/KeyWordsTable";
import OneDaySEOTrends from "containers/OneDaySEOTrends";
import LastSEOTrends from "containers/LastSEOTrends";
import OneDaySEOThemes from "containers/OneDaySEOThemes";
import LastSEOThemes from "containers/LastSEOThemes";

interface tabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const SEOTool = () => {
  const [value, setValue] = useState(0);
  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const CustomTabPanel = (props: tabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Layout>
      <h1 className="text-2xl font-bold mb-4">SEO Tool</h1>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Last 2H Search" {...a11yProps(0)} />
            <Tab label="Last Day Search" {...a11yProps(1)} />
            <Tab label="Last 2H topics" {...a11yProps(2)} />
            <Tab label="Last Day topics" {...a11yProps(3)} />
            <Tab label="Key Words" {...a11yProps(4)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <LastSEOTrends />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <OneDaySEOTrends />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <LastSEOThemes />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <OneDaySEOThemes />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <KeyWordsTable />
        </CustomTabPanel>
      </Box>
    </Layout>
  );
};

export default SEOTool;
