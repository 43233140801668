import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { GridColDef } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import HistoryChart from "components/charts/HistoryChart";
import Table from "components/comon/Table";
import dayjs from "dayjs";
import { axiosCall } from "helpers/axios";
import { useState } from "react";

const LastSEOTrends = () => {
  const [info, setInfo] = useState("");
  const [open, setOpen] = useState(false);

  const { isPending, error, data, refetch } = useQuery({
    queryKey: ["tendencias_all"],
    queryFn: () =>
      axiosCall().post("/data", {
        query:
          "SELECT KEYWORDS_SEARCH_TRENDS.id AS id, KEYWORDS_SEARCH_TRENDS.label AS label, KEYWORDS_LIST.label AS keyword, KEYWORDS_LIST.country AS country, KEYWORDS_SEARCH_TRENDS.keyword_id, JSON_ARRAYAGG(JSON_OBJECT('created_at', created_at,'value', value)) AS historial_values FROM `KEYWORDS_SEARCH_TRENDS`, `KEYWORDS_LIST` WHERE KEYWORDS_LIST.id = KEYWORDS_SEARCH_TRENDS.keyword_id AND KEYWORDS_SEARCH_TRENDS.created_at >= NOW() - INTERVAL 3 HOUR GROUP BY KEYWORDS_SEARCH_TRENDS.label ORDER BY KEYWORDS_SEARCH_TRENDS.value DESC;",
      }),
    select: ({ data }) => {
      return data?.payload;
    },
  });

  const handleOpen = (value: string) => {
    setInfo(value);
    setOpen(true);
  };

  const getTrend = (item: string) => {
    if (item) {
      const array = JSON.parse(item);

      array.sort((a: any, b: any) =>
        dayjs(b.created_at).diff(dayjs(a.created_at))
      );
      const avg =
        array.reduce((total: any, valor: any) => total + valor.value, 0) /
        array.length;
      const last = array[0];
      return (
        <div className="flex items-center justify-between">
          {last.value > avg ? (
            <ArrowDropUp style={{ color: "green", height: 45, width: 45 }} />
          ) : last.value === avg ? (
            <span className="w-10" />
          ) : (
            <ArrowDropDown style={{ color: "red", height: 45, width: 45 }} />
          )}
          <span>{last.value.toLocaleString()}</span>
        </div>
      );
    }
    return "--";
  };

  const columns: GridColDef[] = [
    { field: "country", headerName: "Country", width: 70 },
    { field: "keyword", headerName: "Keyword", width: 140 },
    { field: "label", headerName: "Search", flex: 20 },
    {
      field: "keyword_id",
      headerName: "Fecha",
      width: 120,
      renderCell: ({ row }) => {
        if (!row.historial_values) {
          return "--";
        }
        const array = JSON.parse(row.historial_values);
        array.sort((a: any, b: any) =>
          dayjs(b.created_at).diff(dayjs(a.created_at))
        ).slice(-1);
        console.log(array[0].created_at)
        return <div>{dayjs(array[0].created_at).format('YYYY-MM-DD HH:mm')}</div>;
      },
    },
    {
      field: "historial_values",
      headerName: "Value",
      width: 150,
      renderCell: ({ value }) => {
        return (
          <div
            onClick={() => handleOpen(value)}
            className="cursor-pointer w-full">
            {getTrend(value)}
          </div>
        );
      },
    },
  ];
  return (
    <div>
      <Table rows={data} columns={columns} loading={isPending} />
      <HistoryChart open={open} setOpen={setOpen} info={info} />
    </div>
  );
};

export default LastSEOTrends;
